import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Jubilate Deo',
    desc =
      `Riprese video ed audio del concerto svolto nella Cappella Musicale della Basilica di San Lorenzo per Johannes Gutenberg University Mainz e l'Università di Firenze`,
    subtitle = '',
    roles = ['Videomaker', 'Tecnico del suono'],
    videoSrc = ['https://www.youtube.com/watch?v=2t2OiDmweYM'],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc}/>
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-jubilate.jpg'
        alt='Cover per il lavoro Jubilate Deo'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          Una fantastica serata di musica policorale fiorentina del XVII secolo
          eseguita da <em>Lilium Cantores</em> e della{' '}
          <em>Cappella Musicale della Basilica di San Lorenzo</em>.
        </span>
        <span>
          L'evento è stata l'occasione per riascoltare poderose pagine
          policorali del Seicento fiorentino (Marco da Gagliano, Filippo Vitali,
          Nicolò Sapiti).
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2 margin-bottom'>
        <span>
          Il concerto è parte integrante del convegno internazionale e
          interdisciplinare “Aural Architectures of the Divine. Sacred Spaces,
          Sound and Rites in Transcultural Perspectives”, organizzato dal
          progetto di ricerca “<em>CANTORIA - Music and Sacred Architecture</em>
          ” ( <em>Università Johannes Gutenberg Mainz</em> ) e dal “
          <em>
            Dipartimento di Storia, Archeologia, Geografia, Arte e Spettacolo
          </em>
          ” ( <em>Università degli Studi di Firenze</em> ), che si è svolto dal
          24 al 26 febbraio 2022.
        </span>
      </p>
      {/* ADD HERE ALL THE PHOTOS */}
      <StaticImage
        src='../../images/photo/jubilate-deo-photo-1.jpg'
        alt='Foto Jubilate Deo 1'
        className='work-img work-img-contain video-1 margin-bottom'
        imgClassName='img shadow-black'
        objectFit='contain'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/jubilate-deo-photo-2.jpg'
        alt='Foto Jubilate Deo 2'
        className='work-img photo-1-alt shadow-black margin-bottom'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/jubilate-deo-photo-3.jpg'
        alt='Foto Jubilate Deo 3'
        className='work-img photo-1 shadow-black'
        quality={100}
      />
      {/* ADD HERE ALL THE LOGOS */}
      <div className='logo-container'>
        <StaticImage
          src='../../images/logo/logo-uni-gutenberg.png'
          alt='Logo Gutenberg University'
          className='work-logo-bigger logo-3'
        />
        <StaticImage
          src='../../images/logo/logo-uni-firenze.png'
          alt='Logo Università di Firenze'
          className='work-logo-bigger logo-1'
        />
        <StaticImage
          src='../../images/logo/logo-fts.png'
          alt='Logo fondazione Fritz thyssen stiftung'
          className='work-logo-bigger logo-2'
        />
      </div>
    </WorkLayout>
  )
}

export default Work
